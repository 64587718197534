<template>
    <div id="supervisor-contact">

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="" @refresh="loadData" :add="true" @plusClicked="plusClicked"
                    @cellDoubleClicked="cellDoubleClicked" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Date",
                        field: "createdAt",
                    },
                    {
                        headerName: "Titre",
                        field: "title",
                    },
                    {
                        headerName: "Description",
                        field: "description",
                    },
                ],
                rows: []
            },
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        plusClicked() {
            this.$router.push({ name: "supervisor-website-blog" })
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("blog").catch(() => this.showLoading = false);
            this.agGrid.rows = response.data;
            this.showLoading = false;
        },
        cellDoubleClicked(row) {
            this.$router.push({ name: "supervisor-website-blog-edit", params: { id: row.data.id } })
        }
    },
}
</script>